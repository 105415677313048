.block-box{
    margin: auto;
    margin-top: 100px;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    min-width:550px;
    max-width:90%;
    height: fit-content;
    gap:20px;
    flex-direction: column;
    flex-wrap: wrap;
    /* border: 5px solid red; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.block-pages{
    display: flex;
    width:100%;
    gap:10px;
    padding:20px;
    min-height:90vh;
    max-height: fit-content;
    /* border: 1px solid red;; */
}

.block-buttons-div{
    margin: auto;
    height:50px;
    display: flex;
    min-width:80%;
    max-width:90%;
    box-sizing: border-box;
    padding:5px;
    justify-content: center;
    font-size: large;
    align-items: center;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


}
.blockform-page-1{
    margin: auto;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 10px;
    gap:10px;
    display: flex;
    min-height: 70vh;
    max-height:fit-content;
    flex-direction: column;
    flex-wrap: wrap;
    /* border: 5px solid red; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.blockform-page-1{
    width:30%;
}
.blockform-page-2{
    margin-top: 20px;
    height: 100%;
    box-sizing: border-box;
    padding: 5px;
    gap:10px;
    display: flex;
    align-items: flex-start;
    min-height: 70vh;
    max-height:fit-content;
    flex-wrap: wrap;
    /* border: 5px solid red; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width:70%;
    gap:5px;
}
.block-keypoints{
    /* border:1px solid red; */
    margin: auto;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    width:50px;
    justify-content: space-between;
    gap:15px;
    flex-wrap: wrap;
}
.block-heading{
    font-weight: bold;
    font-size: larger;
    color: gray;
    margin-right: auto;
}
.block-review-page{
    display: flex;
    width:90%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    flex-direction: column;
    padding:5px;
    font-size: small;
}
.block-role-tiles{
    display: flex;
    justify-content: center;
    padding:5px;
    height:fit-content;
    flex-wrap: wrap;
    gap:5px;
}
.block-keypoints-header{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    justify-content: left;
    align-items: left;
}
.block-keypoints-title{
    /* border:1px solid red; */
    font-weight: bold;
    font-size: large;
    color: grey;
}
.block-role-tile{
    width:fit-content;
    box-sizing: border-box;
    justify-content: center;
    background-color: grey;
    color: white;
    padding: 5px;
    border-radius: 6px;
}
.block-role-tile{
    cursor: pointer;
}
.block-keypoints-tiles{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap:5px;
    padding:10px;
}
.block-keypoint-tile{
    display: flex;
    box-sizing: border-box;
    padding:5px;
    align-items: center;
    width: 100%;
    max-height: 350px;
    height: fit-content;
    gap:3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.block-keypoint-tile:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.block-keypoint-text{
    display:flex;
    justify-content: flex-start;
    text-overflow: ellipsis;
    width:80%;
    height: fit-content;
    font-size: smaller;
    /* border: 1px solid red; */
}
.block-keypoints-clear:hover{
    cursor: pointer;
}
.block-keypoint-clear{
    margin-left: auto;
}
.block-keypoint-clear:hover{
    cursor: pointer;
}
.block-keypoints-div{
    display: flex;
    flex-direction: column;
    gap:5px;
    justify-content: flex-start;
    width:350px;
    height: fit-content;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.keypoints-title{
    text-align: center;
    font-weight: bold;
    font-size: larger;
}
.block-keypoint-index{
    width: fit-content;
    /* border: 1px solid red; */
}
.block-selectors-row{
    display: flex;
    width:100%;
    gap:20%;
}
.block-selectors-role-row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height:fit-content;
    /* border: 1px solid red; */
    padding:5px;
    /* border-right: 1px solid black; */
    gap:10px;
    /* align-items: center;
    justify-content: center; */
}
.block-selectors-row2{
    display: flex;
    flex-direction: column;
    width:100%;
}
.block-selectors-col{
    width:40%;
}
.block-probearea-tile{
    display: flex;
    box-sizing: border-box;
    padding: 3px;
    width: fit-content;
    height: fit-content;
    text-align: center;
    text-overflow: ellipsis;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}
.block-probeareas-tiles{
    display: flex;
    padding:5px;
    max-width: 100%;
    justify-content: flex-start;
    gap:5px;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* border: 1px solid red; */
}
@media only screen and (max-width: 500px) {
    .block-keypoint-text{
        width:50px;

    }

    .block-keypoint-index{
        width:50px;
    }
    .block-keypoint-tile{
        gap:10px;
    }

    .block-keypoints-title{
        width:100%;
    }
    .block-keypoint-tile{
        width: 98%;
    }
    .block-buttons-div{
        padding:5px;
        gap:20px
    }
    .block-buttons-div > h2{
        font-size: medium;
        align-self: center;
    }

  }
  @media only screen and (max-width: 1000px) {
    .block-pages{
        display: flex;
        flex-direction: column;
        width:100%;
    }
    .blockform-page-1,.blockform-page-2{
        width:100%
    }
  }
  @media only screen and (max-width: 1000px) {
    .block-keypoints{
        flex-direction: column;
        align-items: center;
        gap:50px;
    }

  }
  @media only screen and (max-width: 600px) {
    .block-selectors-col{
        width:100px;
        box-sizing: border-box;
        padding:5px;
    }
    .block-selectors-row{
        gap:20%;
    }
  }
  @media only screen and (max-width: 450px) {
    .block-keypoints-div{
        width:100%;
    }
  }
  .block-selectors-row-review{
    display: flex;
    width:100%;
    justify-content:space-between ;
  }
  .block-selectors-col-review{
    display: flex;
    justify-content: center;
    align-items: center;
    width:50%;
    flex-direction: column;
  }
  .block-keypoints-review{
    display: flex;
    justify-content: center;
    gap:20px;
    flex-wrap: wrap;

  }
  .role-displayer{
    align-self: stretch;
    /* min-height: auto; */
    /* max-height: 100%; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }