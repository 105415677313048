.candidate-profile {
    color:#FA5F55;
    margin-left:10px;
    cursor:pointer;
    background:#ECECEC;
    border-radius:50%;
    font-size:30px;
    padding:5px;
}

.candidate-profile:hover {
    transform: scale(1.2);
}