.nav-links{
    display: flex;
    box-sizing: border-box;
    width: auto !important;
    height:50px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: sticky;
    justify-content: space-between;
}
.nav-link{
    width:100px;
    align-self: flex-start;
}
.nav-links:hover{
    cursor: pointer;
}