.blocks-displayer-box{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:30px;
    background-color: #f9f9f9;
}
.block-display-box{
    display:flex;
    flex-direction: column;
    width:50%;
    box-sizing: border-box;
    opacity: 1;
    /* border: 1px solid red; */
    background-color: #fff;
    height:fit-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    
}
.block-display-header{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    background-color: #eee;
    height: 75px;
    border-radius: 10px 10px 0px 0px;

}
.block-display-header > p{
    font-size: large;
    font-weight: bold;
}
.block-display-content{
    display: flex;
    width:80%;
    box-sizing: border-box;
    padding:10px;
    justify-content: space-between;
}
.block-display-title{
    color:grey;
    font-weight: bold;
}
.block-display-col{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding:5px;
}
.block-display-options{
    box-sizing: border-box;
    padding:10px;
    width:30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    /* border: 1px solid red; */
}
.block-display-options-Box{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    width:250px;
    position:relative;
    z-index: 1;
}
.block-display-item:hover{
    cursor: pointer;
}
.filter-box{
    display: flex;
    box-sizing: border-box;
    padding: 20px;
    width:30%;
    height:fit-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
    border-radius: 10px;
    gap:30px;
}

@media only screen and (max-width: 700px) {
    .block-display-box{
        width:95%;
    }

}