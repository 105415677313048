.candidate-form{
    width:800px;
    height: fit-content;
    display:flex;
    flex-direction: column;
    gap:5px;
    margin:auto;
    margin-top:30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:10px;
    box-sizing: border-box;
}
.candidate-form-option-title{
    font-size: medium;
    font-weight: bold;
}
.candidate-form-header{
    width: 100%;
    height:50px;
    text-align: center;
    padding:10px;
    font-size: large;
    font-weight: bold;
    margin: auto;
    background-color: #eee;

}

input {
    border-radius: 5px;
    padding: 13px;

}