.template-box{
    /* border: 1px solid black; */
    
    margin: auto;
    display: flex;
    box-sizing: border-box;
    padding:5px;
    min-width:500px;
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: grey;

}
.template-static-box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding:10px;
    /* border:1px solid red; */
    min-width: 600px;
    width:95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    gap:50px;
   
}
.status-div{
    display:flex;
    width:fit-content;
    font-size: large;
    height:45px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.templateCircle{
    width:20px;
    height:20px;
    border-radius: 50%;
}
.template-div{
    width:50%;
    height:100px;
    display:flex;
}
.template-title-box{
    font-size: large;
    margin: auto;
    font-weight: bolder;
}
.template-edit-icon{
    font-size: xx-large;

}
.template-edit-icon:hover{
    cursor: pointer;
}

.template-static-header{
   

    font-weight: bolder;
    font-size: xx-large;
    text-align: center;
}
.template-static-selectors{
    min-width: 500px;
    padding: 10px;
    display: flex;
    gap:25px;
    width:100%;
    justify-content: center;
}
.template-table{
    min-width: 500px;
    width:100%;
    border-collapse: collapse;
    /* border: 1px solid red; */
    text-align: left;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 30px;
}
.template-table > thead > th{
    padding:12px;
    background-color: rgb(254, 65, 77);
    height: 75px;
    font-size: x-large;
}
.template-table > tbody > tr{
    width:100%;
    /* border: 1px solid red; */
}
.template-table > tbody > tr:nth-child(even) {background-color: #f2f2f2;}
.template-table > tbody > tr > td{
    padding:12px;
    font-size: large;
    /* border: 1px solid red; */

}
.table-remove-button{
    width:15px;
    /* border: 1px solid red; */
}
.table-remove-button:hover{
    cursor: pointer;

}

@media only screen and (max-width: 500px) {
    .template-static-box{
        gap:20px;
    }
    .template-static-selectors{
        flex-direction: column;
    }
}
@media only screen and (max-width: 800px) {

    .template-static-selectors{
        flex-direction: column;
    }
}
@media only screen and (max-width: 1200px) {
    .template-static-box{
        gap:20px;
    }
}
.template-comments{
    width:100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height:fit-content;

}
.sections-tiles-displayer:nth-child(odd){
    background-color: #ff2;
}
.template-comment{
    width: 100%;
    height:fit-content;
    box-sizing: border-box;
    padding:10px;
    gap:25px;
}
.template-comment-header{
    display: flex;
    gap:50px;
}
.template-comment-username{
    font-size: larger;
    font-weight: bolder;
}
.template-comment-time{
    font-weight: 200;
    color:grey;
}
.template-comment-text{
    font-size: large;
}
