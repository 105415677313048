.create-form-column{
    display: flex;
    flex-direction: column ;
    min-width: 200px;
    width: fit-content;
    text-align: center;
}
.create-form-column-heading-box{
    box-sizing: border-box;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    height:75px;
    text-align: center;
    color: #eee;
    font-weight: bold;
    font-size: large;
}
.create-form-column-content-box{
    box-sizing: border-box;
    text-align: left;
    font-size: small;
    word-spacing: 1px;
    /* letter-spacing: 1px; */
    line-height: 1.2;
    padding:0px;
    border-right: 1px solid grey;
    height: 100%;
}
.create-form-column-inputs{
    display: flex;
    justify-content: space-around;
}