@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  margin-bottom: 150px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button-colors{
  background: black;
  color: #fff;
}
.button-colors:hover {
  background: black;
  color: #fff;
}
.data-amplify-container{
  margin-top: 20%;
}
.amplify-container{
  height: 100vh;
}

[data-amplify-authenticator] [data-amplify-container] {
    width: var(--amplify-components-authenticator-container-width-max);
}
[data-amplify-authenticator] [data-amplify-container]{
    place-self: center;
    margin-top: 40px;
}
.icon-hover:hover{
  color:red;
}
.button-hover:hover{
  background-color: red;
}


