.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    z-index: 1;
    
}

.modal {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
}

.submit-box {
    display: flex;
    width: 90%;
    justify-content: center;
    margin: auto;
}

.submit-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:auto;
}

.submit-checkbox {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: center;

}

.submit-role{
    display: flex;
    gap: 30px;
    width: 80%;
    padding:10px;
}

.submit-remarks{
    display: flex;
    gap: 105px;
    width: 80%;
    padding:10px;
}

.submit-status{
    display: flex;
    gap: 135px;
    width: 80%;
    padding:10px;
}

.submit-button {
    display: flex;
    width: 80%;
    padding:10px;
    justify-content: center;
}

.logout-modal {
    display: flex;
    width: 100%;
    justify-content: center;
}
