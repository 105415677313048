.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) ;
    z-index: 1;
    
}

.modal {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    background: white;
    border-radius: 10px;
    text-align: center;
}

.wrapper {
    padding-bottom: 40px;
}

.interviewer-name_component{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.interviewer-expertise_component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.interviewer-profile_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 200px;
}
.interviewer-expertise_icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.role-tree_parent{
    padding:5px;
    color: white;
    background: black;
    border: 3px solid #FA5F55;
    display: inline-block;
    border-radius: 8px;
}

.role-tree_child {
    padding:5px;
    color: white;
    background: #FA5F55;
    border: 3px solid #FA5F55;
    display: inline-block;
    border-radius: 8px;
}