.interviewer-form{
    width:auto;
    height: fit-content;
    display:flex;
    flex-direction: column;
    gap:5px;
    margin:auto;
    margin-top:10px;
    margin-left: 8rem;
    margin-right: 8rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding:10px;
    box-sizing: border-box;
}
.interviewer-form-option-title{
    font-size: medium;
    font-weight: bold;
    margin-bottom: 0px;
}
.interviewer-form-header{
    width: 100%;
    height:50px;
    text-align: center;
    padding:10px;
    font-size: large;
    /* font-weight: bold; */
    margin: auto;
    background-color: #eee;

}

input {
    border-radius: 5px;
    padding: 13px;
    
}

.inline{
    
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: middle;
    margin: auto;
  
}

.boxes {
    display: flex;
    align-items: center;
}

.boxes label {
    cursor: pointer;
    display: flex;
}

.boxes input[type='checkbox']:checked + label::before {
    content: '\002714';
    background-color: #27AE60;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.boxes input[type='checkbox']:disabled + label::before {
    background-color: #ccc;
    border-color: #999;
}
.boxes input[type='checkbox']:disabled + label::before {
    background-color: #ccc;
    border-color: #999;
}
.boxes input[type='checkbox']{
    cursor: pointer;
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.boxes label::before {
    content: '';
    width: 1.25em;
    height: 1.25em;
    border-radius: .15em;
    margin-right: 0.25em;
    margin-top: 0.25em;
    border: .05em solid black;
}
  
.line{
    /* display: flex; */
    /* box-sizing: border-box; */
    width: auto !important;
    border: 1px solid black;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 1rem;
  padding: 2rem 2rem;
  text-align: center;
  /* width: 1000px; */
  /* margin-top:10px; */
  /* margin: auto; */
  flex-direction: column;
}