.createform{
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    padding: 25px;
}
.form-wrapper{
    width:95%;
    overflow: auto;
    margin: auto;
    box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px ;

}
.interviewer-table-box{
    display: flex;
    margin-top: 30px;
    width:100%;
    box-sizing: border-box;
    justify-content: space-around;
}
.score-box{
    width: 80%;
    box-sizing: border-box;
    padding:20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.finalremarks{
    display: flex;
    width:20%;
    justify-content: flex-end;
    align-items: center;
    font-size: large;
    font-weight: bold;
}
.Modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width:80%;
    height: 250px;
    top: 150px;
    left: 150px;
    right: 40px;
    bottom: 40px;
    background-color: #eee;
}