.profile-div{
    display: flex;
    flex-direction: column;
    min-width: 150px;
    max-width: 500px;
    box-sizing: border-box;
    padding:20px;
    margin: auto;
    margin-top: 30px;
    height:500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    justify-content: center;
    align-items: center;
}