.nav-heading {
    font-size: 60px;
    text-align: center;
    margin-top: 150px;
    margin-left: 100px;
    margin-right: 100px;
    color: #A9A9A9;
}

.candidate-icon {
    position: absolute;
    left: 30px;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background: #e0e0e0;
    transform: scale(2);
    padding:3px;
    
}

.grading-icon {
    position: absolute;
    right: 30px;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background: #e0e0e0;
    transform: scale(2);
    padding:3px;
    
}