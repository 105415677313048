.chart-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    gap:20px;
}
.chart-rows{
    display: flex;
    width:95%;
    gap:20px;
    justify-content: center;
    align-items: center;
    margin: auto;

    margin-top: 30px;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.chart-box{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: fit-content;
    padding: 5px;
}
.root {
    padding: 10px;
    height: auto;
  }
.name {
    font-size: 18px;
    font-weight: 500;
  }
.details {
    display: 'flex';
    align-items: 'center';
    padding: '5px 5px 13px 5px';
  }
.avatar {
    height: 60;
    width: 60;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 25;
  }
.progress {
    margin-top: 2px;
  }
.uploadButton {
    margin-right: 2px;
  }
.divider {
    margin-bottom: 3px;
  }
.textRow {
    margin-top: 2px;
  }
.dateText {
    width: 60;
    margin-right: 30;
    display: 'inline-block';
    font-size: 13px;
  }
.smallBoldText {
    font-size: 14px;
    font-weight: 500;
  }

  .btnFillup {
    display: inline-block;
    padding: 16px;
    overflow: hidden;
    color: #1746A2;
    text-decoration: none;
    border: 1px solid #1746A2;
    border-radius: 3px;
    background-position: 0 -100%;
    background-repeat: repeat-x;
    transition: background-position .8s ease;
  }
  
  .btnFillup:hover {
    background-position: 100% 50%;
    border-color: #1746A2;
    color: #fcfcfc; 
  }

  .anglebg {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAABkCAYAAACoy2Z3AAABOklEQVR42u3VIQEAAAjAMOifgKgoyIDAbRFunlHTAQBHaSAAGAgABgKAgQBgIABgIAAYCAAGAoCBAGAgAGAgABgIAAYCgIEAYCAAYCAAGAgABgKAgQBgIABgIAAYCAAGAoCBAGAgAGAgABgIAAYCgIEAYCAAYCAAGAgABgKAgQBgIDIAYCAAGAgABgKAgQCAgQBgIAAYCAAGAoCBAICBAGAgABgIAAYCgIEAgIEAYCAAGAgABgKAgQCAgQBgIAAYCAAGAoCBAICBAGAgABgIAAYCgIEAgIEAYCAAGAgABgKAgQCAgQBgIAAYCAAGAoCBGAgABgKAgQBgIAAYCAAYCAAGAoCBAGAgABgIABgIAAYCgIEAYCAAGAgAGAgABgKAgQBgIAAYCAAYCAAGAoCBAGAgABgIABgIAD8WEIIBGLtr1zoAAAAASUVORK5CYII=");
  }