

.flex-container {
    background-color: #E5E4E2;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    margin: 20px 0 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 60px;
    box-sizing: border-box;
}



.profile_right{
    background-color: #f5f5f5;
    border-radius: 6px;
    width: 100%;
    display: inline-block;
    /* margin: 10px 0 0 55px; */
}

.profile {
    background-color: #f5f5f5;
    border-radius: 6px;
    width: 100%;
    display: inline-block;
    margin:10px;
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 5px;
}

.profile_grid {
    background-color: #f5f5f5;
    border-radius: 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_name {
    line-height: 18px;
    padding: 20px;
}

.profile_name .name {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
}

.profile_name .title {
    font-size: 13px;
    color: #777;
}

.timeline {
    padding: 0 !important;
}
.timeline .timeline_header {
    padding-top: 10px;
    text-decoration: underline #FA5F55 2px ;
}
.timeline .timeline_firstItem {
    min-height: 0px !important;
}
.timeline .MuiTimelineItem-root {
    min-height: 0px !important;
}
.timeline .MuiTimelineItem-missingOppositeContent:before {
    display: none;
}
.timeline .timeline_dot_header {
    color: black !important;
    background-color: #FA5F55 !important;
    font-size: small !important;
    padding: 5px !important;
}
.timeline .timeline_dot {
    color: black !important;
    border-color: #FA5F55 !important;
    padding: 2px !important;
}
.timeline .MuiTimelineItemConnector-root {
    background-color: #eee !important;
}
.timeline .timeline_dot_header > .MuiSvgIcon-root {
    font-size: 20px;
    color: #3C4048;
}
.timeline .separator_padding {
    padding-left: 18px !important;
}

.timeline .timeline_content {
    padding-top: 3px !important;
    margin-bottom: 20px;
}

.profile_information {
    top: 0;
    left: 0;
}

.profile_information .timeline .timeline_content {
    margin-top: 2px;
    margin-bottom: 12px;
}

.timelineItem_text {
    font-size: 13.5px;
    color: #787878;
    font-weight: 400;
}

.timelineItem_text > span{
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.container_shadow {
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -moz-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.custom_btn {
    background-color: #39B5E0 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border: 0 !important;
    border-radius: 50px !important;
    padding: 1px 12px !important;
    line-height: 35px !important;
    min-width: 179px !important;
    outline: none !important;
}

.btn_icon_container {
    color: #000 !important;
    background: #fff !important;
    border-radius: 50px !important;
    height: 27px !important;
    width: 27px !important;
    line-height: 29px !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.btn_icon_container_box-link {
    color: #000 !important;
    background: #FA5F55 !important;
    border-radius: 50px !important;
    height: 35px !important;
    width: 35px !important;
    line-height: 29px !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 25px;
}

.btn_icon_container_box-link > .MuiSvgIcon-root {
    font-size: 20px !important;
}

.btn_icon_container > .MuiSvgIcon-root {
    font-size: 16px !important;
}

.custom_btn .btn_text {
    font-size: 14px !important;
    text-transform: none !important;
    text-align: center;
    width: 100%;
    margin-right: 5px;
    margin-left: 5px;
    color: #000;
}
.profile .button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.section_content {
    padding: 20px;
}

.section_title_text {
    font-weight: 600;
    font-size: x-large;
    margin: 0;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: center;
    
}

.timeline-item{
    margin: 1rem;
    width: calc(45% - 2rem);
    min-width: 17.5rem;
}

.timeline-item a:hover {
    cursor: pointer;
}

.timeline-item a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 10px;
    /* background: #FA5F55; */
    border: 1px solid #FA5F55;
    border-radius: 10px;
}


.timeline-item_content {
    padding: 0;
    margin-top: 30px;
   
}
/* .timeline-item_content:focus {
    border: thin solid black
} */
.timeline-item_info {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

.timeline-item_info-heading {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width: 100%;
}

.timeline-item_info span {
    font-size: small;
    color: #FA5F55;
}

.timeline-item_info h3 {
    margin: 0;
    font-weight: normal;
    color: #000;
}

.marks-section__else-content {
    text-align: center;
    margin-top: 250px;
    color: grey;
    font-size: 50px;
}

.detail-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    box-sizing: border-box;
    gap: 60px;
    width: 100%;
}

.detail-container-box {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
    width: 370px;
    margin: 10px 0 15px 0;
}
.detail-container .detail-container-box .detail-heading {
    font-size: small;
}
.detail-container .detail-container-box .detail-box-link {
    font-size: small;
}


.detail-heading {
    color: grey;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    
}
.detail-box-link {
    color: grey;
    font-size: large;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width:96%;
}

.detail-content {
    font-size: medium;
    border-bottom: 1px solid #808080;
    width: 100%;
    text-align: left;
    padding:5px;
}
.detail-container-button {
    display: flex;
    justify-content: flex-start;
    height: fit-content;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 30px;
}

.detail-container-update-button {
    display: flex;
    justify-content: left;
    height: fit-content;
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
}

.details-container-button-box {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 200px;
    gap: 300px;
}
.candidate_initial {
    padding-right: 150px;
}

.flex-content {
    font-size: medium;
    border-bottom: 1px solid #808080;
    padding: 5px;

}
.flex-content span {
    color: grey;
    font-size: small;
}
.outer-div {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    box-sizing: border-box;
    width: 100%;
}
.section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: fit-content;
    align-items: stretch;
}
.flex_items1 {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    gap: 1px;
}
.flex_items2 {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
}

.candidate_name_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    
    border-radius: 5px;
}