.form-section-div{
    width: 90%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* border: 1px solid red; */
    margin: auto;
}
.section-wrapper{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:auto;
   
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}
.sections-box{
    height:fit-content;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    /* padding:10px; */
    box-sizing: border-box;
    gap:60px;
    width: 100%;
    /* border:1px solid red; */

}
.section-box{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width:350px;
    height: 100%;
}
.section-heading{
    color:grey;
    font-size: large;
    /* border: 1px solid red; */
}
.section-content{
    font-size: medium;
    border: 3px solid #e0e0e0;
    width: 110%;
    text-align: left;
    padding:5px;
    background-color: #e0e0e0;
    box-sizing: border-box;
    border-radius: 5px;
}
.remarks{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    /* padding: 10px; */
}
.probeareas-expectations{
    width: 100%;
    display: flex;
    /* padding:10px; */
    justify-content:flex-start;
    gap:20%;
}
.probeareas-div{
    display: flex;
    flex-direction: column;
    width: 40%;
    box-sizing: border-box;
}
.sections-nav{
    height:fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    box-sizing: border-box;
    /* border:1px solid black; */
    /* flex-wrap: wrap; */
    overflow-x: auto;
}
.sections-nav-wrapper{
    display:flex;
    height:fit-content;
    width:98%;
    justify-content:space-around;
    height:fit-content;
    align-items:center;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.form-icon{
    width:50px;
    height:50px;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 3px;
    background-color: #d5d5d5;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sections-nav::-webkit-scrollbar {
    display: none;
    width:5px;
  }


  .confetti-container {
    width: 100%;
    height: 100%;
    position: relative;
  }