.create-form-row{
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.create-form-row-title{
    background-color: #fe414d;
    color:#fff;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.create-form-row-columns-box{
    display: flex;
    min-width: 100%;
    gap:0px;
    width: fit-content;
    height: fit-content;
}
.create-form-row-indiacator{
    display: flex;
    height:50px ;
    width: fit-content;
    /* border: 1px solid red; */
    background-color: transparent;
}
.create-form-row-number-box{
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;

    text-align: center;
    width: fit-content;
    color: #212121;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.form-subsection{
    background-color: #eee;
}
.form-subsection > .create-form-column-content-box{
    font-size: medium;
}

.rpa-content-wrapper{
    display: flex;
    flex-direction: row;
    gap:10px;
    padding:5px;
}

.rpa-content-wrapper .rpa-key {
    border: 1px solid #e0e0e0;
    background-color: #e0e0e0;
    border-radius: 5px;
    padding: 2px;
}
