.add-row-col-box{
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    /* position: fixed; */
    /* border: 1px solid red; */
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
    gap:25px;
}