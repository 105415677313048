.template-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width:100%;
    /* margin-bottom:auto; */
    /* border: 1px solid red; */
    gap:15px;

}
.template-static-box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding:10px;
    /* border:1px solid red; */
    min-width: 600px;
    width:95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    gap:10px;
    margin-left: 2%;
   
}
.template-title-box{
    font-size:large;
    /* margin: auto; */
    font-weight: bolder;
    /* border: 1px solid red; */
}
.template-edit-icon{
    font-size: xx-large;

}
.template-edit-icon:hover{
    cursor: pointer;
}
.template-title{
  
    align-items: center;
    width: 100%;
   padding-right:20px;
    display: flex;
    min-height: 40px;
    height:fit-content;
    box-sizing: border-box;
    justify-content: center;
}
.template-static-header{
    font-weight: bolder;
    font-size: medium;
    text-align: center;
}
.template-static-selectors{
    min-width: 500px;
    padding: 10px;
    display: flex;
    gap:25px;
    width:100%;
    justify-content: center;
    /* border: 1px solid red; */
}
.template-table{
    min-width: 500px;
    width:100%;
    border-collapse: collapse;
    /* border: 1px solid red; */
    text-align: left;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 30px;
}
.template-table > thead > th{
    padding:12px;
    background-color: rgb(254, 65, 77);
    height: 50px;
    font-size: medium;
    color:white;
    /* border-right: 1px solid grey; */

}
.template-table > tbody > tr{
    width:100%;
    /* border: 1px solid red; */


}
.template-table > tbody > tr:nth-child(even) {background-color: #f2f2f2;}
.template-table > tbody > tr > td{
    padding:5px;
    font-size: medium;
    /* border-right: 1px solid grey; */

}

.table-remove-button{
    width:15px;
    /* border: 1px solid red; */
}
.table-remove-button:hover{
    cursor: pointer;

}
.template-row-subsection{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    box-sizing: border-box;
    padding: 5px;
}

@media only screen and (max-width: 500px) {
    .template-static-box{
        gap:20px;
    }
    .template-static-selectors{
        flex-direction: column;
    }
}
@media only screen and (max-width: 800px) {

    .template-static-selectors{
        flex-direction: column;
    }
}
@media only screen and (max-width: 1200px) {
    .template-static-box{
        gap:20px;
    }
}

