.sections-div{
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 100%;
    min-height: 100vh;
    max-height: fit-content;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFF;
}
.sections-header{
    box-sizing: border-box;
    padding-left:35px;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-around;
    background-color: #00e6c3;
}
.sections-tiles-displayer{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:10px;
    box-sizing: border-box;
    padding:20px;
}
.sections-header > .sections-title-header > p{
    font-size: 20px;
    font-weight: 500;
    color:#000;
}
.section-div{
    display: flex;
    flex-direction: column;
    height:fit-content;
    width:70%;
    max-width:850px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
     */
     box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* border-radius: 15px; */
    margin:auto;
}
.add-subsection-button{
    /* border: 1px solid red; */
    width:70%;
    max-width:850px;
    margin: auto;
}
.subsection-div{
    display: flex;
    gap:5px;
    width: 100%;
    box-sizing: border-box;
    padding-left:15px;
    height:fit-content;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid green; */
    text-align: center;
}
.section-header{
    display: flex;
    justify-content: space-between;
    gap:100px;
    box-sizing: border-box;
    padding:5px;
    width:100%;
    text-align: center;
    font-weight: bold;
    font-size: small;
    color: #000 ;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    /* border-radius: 15px 15px 0px 0px; */
    background-color: #FFF;
    
}
.section-header-icons{
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    min-width: 150px;
    /* border: 1px solid red; */
    justify-content: space-around;
    align-items: center;
}
.sub-sections-name{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding:3px;
    width:100%;
    text-align: center;
    font-weight: bold;
    font-size: small;
    color: #000;
    
}

.subsection-icons{
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    min-width: 150px;

    /* border: 1px solid red; */
    justify-content: space-around;
}
.subsection-title{
    font-weight: 400;
    font-size: small;
    color: #000;
}
.subsections-div{
    display: flex;
    flex-direction: column;
    gap:2px;
    /* border: 1px solid red; */
}
.subsection-div:nth-child(odd){
    background-color: #eee;
}
.section-header:hover{
    cursor: pointer;
}
@media only screen and (max-width: 600px) {

    .section-div,.add-subsection-button{
        width:95%;
    }
    .section-header{
        font-size: small;
        padding: 10px;
        gap:10px;
    }
    .subsection-title{
        font-size: smaller;
    }
    .subsection-icons{
        width:10px;
        padding: 2px;
    }
    .section-header-icons{
        width:30px;
    }
}
