.section-editor-components{
    display: flex;
    width:450px;
    margin:auto;
    margin-top: 20px;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    padding:20px;
    align-items: center;
}
.section-editor-component{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    width:350px;
}
.section-editor-component-title{
    font-weight: bold;
    font-size: large;
    color:#000;
}